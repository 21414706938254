import React from 'react';
import './styles.scss';

import '@DESelectMC/deselect-component-library/dist/cjs/index.css';
import { NavBar } from '@DESelectMC/deselect-component-library';
import Constants from '../../constants/Constants';

const NavigationBar = ({ user }) => {
  /**
   * Get navigation bar options
   * @returns {void}
   */
  const getNavBarOptions = () => {
    const engageStatus = user?.engageInstallationStatus?.organisationExists;
    const segmentStatus = user?.segmentInstallationStatus?.organisationExists;
    const stackNumber = user?.stackNumber;

    let segment = Constants.DESELECT_SEGMENT_STAGING_APP_NAME;
    let engage = Constants.DESELECT_ENGAGE_STAGING_APP_NAME;

    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      segment = Constants.DESELECT_SEGMENT_PRODUCTION_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_PRODUCTION_APP_NAME;
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'release') {
      segment = Constants.DESELECT_SEGMENT_RELEASE_APP_NAME;
      engage = Constants.DESELECT_ENGAGE_PRODUCTION_APP_NAME;
    }

    let engageLink = 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000JeGMiUAN';

    let segmentLink = 'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000G0yE1UAJ';

    if (engageStatus) {
      engageLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${engage}`;
    }

    if (segmentStatus) {
      segmentLink = `https://mc.${stackNumber}.exacttarget.com/cloud/#app/${segment}`;
    }

    const options = [
      {
        id: 'Segment',
        name: 'Segment',
        url: segmentLink,
      },
      {
        id: 'Engage',
        name: 'Engage',
        url: engageLink,
      },
      {
        id: 'Search',
        name: 'Search',
        url: '',
      },
    ];

    return options;
  };

  return (
    <div className="navbar">
      <NavBar
        options={getNavBarOptions()}
        selected="Search"
      />

      <span>
        <a
          href={Constants.DESELECT__WEBSITE_LINK}
          rel="noopener noreferrer"
          target="_blank"
          id="deselect-website-link"
        >
          <img
            className="logo-img"
            width="100"
            height="100"
            src="/assets/images/deselect_logo.svg"
            alt="DESelect"
          />
        </a>
      </span>
    </div>
  );
};

export default NavigationBar;
